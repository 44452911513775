.logo {
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
}

.main {
  position: fixed;  
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  z-index: 1000; 
  bottom: 30px; 
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu__trigger,.menu__item  {
  width: 40px;
  height: 40px;
} 

.menu__trigger {
  background-color: var(--background-3);
  border: var(--border-style-1);
  padding: 8px;
  cursor: pointer;
  transition: 0.35s ease;
  }

/* Menu Icons */
.menu__item {
  position: absolute;
  /* width: 40px;
  height: 40px; */
  padding: 12px;
  background-color: var(--accent-5);
  border: var(--border-style-1);
  z-index: -500;  
  opacity: 0;
  bottom: .1px;    
}

/* Transition duration delay */
.menu__item--0 {
  transition: 0.35s ease;
  left: calc(50% - 30px);
}

.menu__item--1 {
  transition: 0.35s ease 0.1s;
  left: calc(50% - 30px);
}

.menu__item--2 {
  transition: 0.35s ease 0.2s;
  right: calc(50% - 30px);
}

.menu__item--3 {
  transition: 0.35s ease 0.3s;
  right: calc(50% - 30px);
}

.is-rotate {
  transform: rotateZ(180deg);
  background-color: var(--accent-5);
  border: var(--border-style-1);
}

/* Positioning the menu item */
.item-0 {
  left: calc(50% - 100px);
  opacity: 1;
}

.item-1 {
  left: calc(50% - 60px);
  opacity: 1;
}

.item-2 {
  right: calc(50% - 60px);
  opacity: 1;
}

.item-3 {
  right: calc(50% - 100px);
  opacity: 1;
}
