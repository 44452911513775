/* Countdown Component Styles */
.Countdown__form-body {
  font-size: var(--font-size-4);
  padding: 100px 0px;
  position: relative;
  /* width: 100%; */
  /* height: 700px; */
  /* margin: 5px 0px 10px 0px; */
}

.Countdown__img-container {
  /* z-index: -1; */
  position: absolute;
  top: 0px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  overflow: hidden;
}

.bgd__image-Countdown {
  position: relative;
  top: 0px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.Countdown__Heading {
  position: relative;
  color: var(--font-color-2);
  font-size: var(--font-size-3);
  margin: 20px 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.Countdown__components-container {
  margin: 20px auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  z-index: 1;
  max-width: 100%;
  /* border: 10px solid pink; */
}

.Countdown__text {
  color: var(--font-color-2);
  font-size: var(--font-size-4);
  padding: 20px 20px;
  display: flex;
  align-items: center;
 position: relative;
  align-items: center;
  z-index: 1;
}

.Countdown__numbers {
  display: inline-block;
  list-style-type: none;
  font-size: calc(0.85rem + 1vw);
  padding: 10px;
  background-color: var(--background-4);
  box-shadow: var(--box-shadow-2);
  margin: 10px 5px;
  z-index: 1;
}

.Countdown__numbers span {
  display: block;
  font-size: calc(1rem + 1vw);
}
