/* @import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono); */
 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
 
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital'); */
 

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
}
 
:root {
  --background-1: #ffff;
  --background-2: #f509ae;
  --background-3: #e8e517cc;
  --background-4: #383838;
  --background-5: #00ff00;
  --background-6: url(./components/img/Nav_Icons/grid.png);
  /* --background-7: #000044; */
  
 --background-7:#151b27;


  --accent-1: #8d8b0dcc;
  --accent-2: #e8e517cc;
  --accent-3: #00ff00;
  --accent-4: #545455;
  --accent-5: #ffff;
  --accent-6: #000;
  --accent-7: #f509ae;
  --accent-8: #3fb1ce;
  

  /* scale from mobile to desktop base 16 */
 --font-size-1: 3.4375rem; /* H1 | 55px / 16px- 2-3.4375rem (32-55px) (default browser font-size) */
--font-size-2: 2.75rem; /* H2 | 44px / 16px - 1.5-2.75rem (24-44px) */
--font-size-3: 2rem; /* H3 | 32px / 16px - 1.25-2rem (20-32px) */
--font-size-4: 1.5rem; /* H4 | 24px / 16px - 1-1.5rem (16-24px) */
--font-size-5: 1.25rem; /* H5 | 20px / 16px - 0.75-1.25rem (12-20px) */
--font-size-6: 1rem; /*H6 | 16px / 16px - 0.67-1rem (10.72-16px)*/



  --font-weight-1: 700;
  --font-weight-2: 600;
  --font-weight-3: 400;

  --font-color-1:  #424d5c;
  --font-color-2: #ffff;
  --font-color-3: #000;
  --font-color-4: #d7d7db;
  --font-color-5: #00ff00;
  --font-semiTransparent: #ffffff80;

  /* styling */
  --box-shadow-1: 6px 6px 6px 0px var(--accent-1);
  --box-shadow-2: 0 12px 12px var(var(--accent-3));
  --box-shadow-3: 0 0 6px #fb00ff99;

  /* Border */
  --border-color-1: #888787;
  --border-color-2: #fb00ff99;

  --border-style-1: 1px solid #d3d3d3;
  --border-radius-1: 20px;

  --border-radius-2: 3px;
}

body {
 /* font-family: "Share Tech Mono", monospace; */
 color: var(--font-color-1);
 background-attachment: fixed;
 min-height: 100%;
}

html {
  color-scheme: dark light;
}
 
.Card__b {
  height: 300px;
  width: 16rem;
  margin: 0 auto;
}

.Card__c {
  height: 0 auto;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
}

.cardP {
  /* max-height: 100vh; */
  /* min-height: 100vh; */
  text-align: center;  
}

 
/* .Grid__container-c {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  border: 5px solid pink;
} */

/* Projects, Countdown ----------------------*/
.Grid__container-b {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
}
/* Styles for screens smaller than 768px (e.g., mobile devices) */
/* @media (max-width: 767px) {
  .Grid__container-b {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
} */

/* Styles for screens smaller than 480px (e.g., smaller mobile devices) */
/* @media (max-width: 479px) {
  .Grid__container-b {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
} */

/* MapContact  ----------------------*/
.Grid__container-e {
  background-color: var(--background-4);
  display: grid;
   grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
   
/* gap: 10px; */

     min-height: 100vh; /* Full viewport height */
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: center;
}

/* .Site__width {
 width: calc(70vw + 10vw);
 margin: 0 auto;
} */

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-auto-rows: minmax(10rem, 1fr); /* Minimum height of 10rem, maximum of 1fr */
  /* min-height: 100vh; */
}