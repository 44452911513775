.Projects_page{
background: var(--background-7);
}

.newsCard {
  position: relative;
  width: 100%;
  height: 40rem;
  overflow: hidden;
  box-shadow: var(--border-style-1);
}

.Projects__mainTitle {
  color: var(--font-color-2);
  font-size: var(--font-size-3);
  color: var(--font-semiTransparent);
  text-transform: uppercase;
  text-align: center;
}

.Projects__bg-img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsCaption {
  position: absolute;
  bottom: 0;
  height: 50%;
  backface-visibility: hidden;
  transition: opacity 0.1s 0.3s, transform 0.4s;
  background: linear-gradient(to bottom, transparent,  #000044 95%);  
}

.newsCaption-header {
  position: absolute;
  font-family: var(--font-family-2);
  color: var(--font-color-2);
  font-size: var(--font-size-2);
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0px 10px;
  overflow: hidden;
}

.newsCaption-content {
  font-family: var(--font-family-2);
  color: var(--font-color-2);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  padding: 0px 5%;
  height: 100px;
  overflow: hidden;
  position: relative;  
  transform: translateY(0);  
  transition: transform 0.6s ease, height 0.4s ease;  
}

/* show hidden text */
.newsCaption:hover .newsCaption-content {
  height: auto; 
  overflow: visible;  
  transform: translateY(-50%);   
}

.news-Slide-up:hover   {
  transform: translateY(0px);
  transition: opacity 0.1s, transform 0.6s;
}

#Project__submit-Btn-1 {
  position: absolute;
  padding: 8px 12px;
  background-color: var(--accent-6);
  font-size: var(--font-size-3);
  color: var(--font-color-2);
  margin: 1rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  display: block;
  width: 90%;
  bottom: 100px;
}
#Project__submit-Btn-1:hover {
  background-color: var(--accent-3);
  color: var(--font-color-3);
  font-weight: var(--font-weight-3);
  transition: opacity 0.1s, transform 0.6s;
}

/* ProjectModal-----TBC------------- */
/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

/* .modal-content { */
  /* background-color: #fff; */
  /* padding: 20px; */
 /* width: 90%;
 height: 80%;
  position: relative;
} */

.modal-close {
  position: absolute;
  top: -20px;
  right: 10px;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;;
}

iframe {
  border: none;
}

