/* DEFAULT text container   */

 

.Contact__Heading {  
  color: var(--font-color-2);
  font-size: var(--font-size-3);
  color: var(--font-semiTransparent);
  margin: 20px 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* DEFAULT text container   */
.Card__text-container-e {
 
  text-align: center;
  position: relative;
}


.Card__text-Company,
.Card__text-Question,
.Card__text-Studio,
.Card__text-Address,
 .Card__text-PostCode, 
 .contact-button  {
   font-size: var(--font-size-6);
  /* font: 1.2rem var(--font-family-1); */
  position: relative;
  display: block;
}

/* .Card__text-Company {
  color: var(--font-color-2);
  font-size: var(--font-size-2);
  bottom: calc(-1 * var(--text-spacing));
} */

.Card__text-Question {
  /* bottom: calc(-1 * var(--text-spacing)); */
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-2);
  padding: 10% 5%;
}

.Card__text-Studio {
  bottom: calc(-10 * var(--text-spacing));
}

.Card__text-Address {
  bottom: calc(-12 * var(--text-spacing));
  padding: 2% 5%;
}

.Card__text-PostCode {
  bottom: calc(-14 * var(--text-spacing));
}

.contact-button{
  bottom: calc(-24 * var(--text-spacing));
}


/* Contact Number ----------------------------- */
/* .Card__text-CountryCode {
  font: 1.2rem var(--font-family-1);
  position: relative;
  bottom: -90px;
  display: inline;
}

.Card__text-ContactNumber {
  font: 1.2rem var(--font-family-1);
  position: relative;
  /* bottom: -90px; 
  display: inline;
}

.Card__text-Email {
  font: 1.2rem var(--font-family-1);
  position: relative;
  bottom: -100px;
  display: block;
} */
 
/* button---------------------------------------------------------- */
.contact-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;   
  color: var(--font-color-2); 
  padding: 0.65rem 3.5rem;
  /* font-size: clamp(14px, 2.0vw, 18px); */
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  border: 1px solid var(--accent-5);
  margin: 10% 0 0 0;
   
}

.contact-button::before {
  content: "";
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease, border-color 0.3s ease;
}

.contact-button:hover::before {
  opacity: 1;
  border-color: var(--accent-5);
}

.contact-button:hover {
  opacity: 1;
  transition-duration: 0.8s;
  border-color: var(--accent-5); 
  background-color: var(--accent-3);
  color: var(--font-color-3);
}

/* Social Media ------------------------------------------------ */

.Footer__container {
  position: relative;
  color: var(--font-color-3);
  background: var(--background-4);
  text-align: center;
  /* text-decoration: none;   */
 padding: 5% 0 100px 0;
}

/* .Footer__ui{
position: relative

} */
.Footer__header {
  color: var(--font-color-2);

}

.Footer__icon {
  /* align-items: center; */
  /* justify-content: center; */
  cursor: pointer;
  /* width: 50px; */
  /* display: inline-flex; */
  filter: invert(100%);
}

.Footer__icon:hover {
  background-color: var(--background-4);
  box-shadow: var(--box-shadow-1);
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}


/* Mapbox branding ----------------------------------------------*/
.mapboxgl-ctrl-logo {
  width: 0px;
  height: 0px;
}

a.mapboxgl-ctrl-logo {
  width: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib-button {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  border-radius: 0px;
  margin: 0px;
  min-height: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
}

.MarkerText {
  color: #fff;
  display: none;
}

/* Mapbox logo */
a.mapboxgl-ctrl-logo {
    /* background-image: url(""); */
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 0px;
    margin: 0 0 ;
    overflow: hidden;
    width: 0px;
}
/* ---------------------------------------------------------- */


@media (min-width: 1024px) {
  .Card__text-container-e {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */
  }
}