.card-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  padding: 10px;
}

.card-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background-7);
  /* background: linear-gradient(to bottom,rgba(255, 255, 255, 0.5), rgba(0, 0, 78, 0.8));  */
  z-index: -1;
  animation: gradientAnimation 7s infinite ease-in-out alternate;
}

@keyframes gradientAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.Card__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  transform: translateY(-40px);
}

.cardP {
  color: var(--font-color-2);
  /*other styles in App.css----------*/
}

.Card__logo {
  display: relative;
  text-align: center;
}

.Card__logo > img {
  display: relative;
  height: 6rem;
  filter: drop-shadow(0.1rem 0.2rem 0.3rem var(--accent-5));
}

.Card__text-projectName {
  font-size: var(--font-size-4);
  color: var(--font-semiTransparent);
  font-weight: var(--font-weight-1);
  display: relative;
  margin-top: 0rem;
}

.Card__headline {
  margin: 1rem;
  font-size: var(--font-size-5);
  text-transform: uppercase;
  letter-spacing: 5px;
  display: block;
}

.Card__subtitle {
  margin: 1rem;
  font-size: var(--font-size-6);
  display: block;
}

.Card__message {
  font-size: var(--font-size-6);
  color: rgb(0, 255, 21);
}

.Card__Partners-container {
  margin-bottom: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

.Card__Partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.Card__Partners > img {
  max-width: 4.5em;
  max-height: 2em;
}

/* --Media  ----------------------------------- */

/* Media query for larger screens (desktop) */
@media (min-width: 768px) {
  .Card__logo > img {
    height: 12rem;
  }
  .Card__text-projectName {
    font-size: var(--font-size-2);
  }

  .Card__headline {
    font-size: var(--font-size-4);
    text-transform: uppercase;
    letter-spacing: 6px;
  }

  .Card__subtitle {
    font-size: var(--font-size-4);
  }

  .Card__Partners-container {
    height: 20rem;
  }

  .Card__Partners {
    gap: 1rem;
  }

  .Card__Partners > img {
    gap: 40rem;
    max-width: 14em;
    max-height: 4em;
  }
}
