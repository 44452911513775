.main__body-about {
  background: var(--background-7);
  min-height: 100%;
  position: absolute;
  width: 100vw;
}

.main__about-header {
  font-size: var(--font-size-2);
  color: var(--font-semiTransparent);
  position: absolute;
  margin: 6.5rem 2rem;
  z-index: 10;

}

.accordion__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: crosshair;
  padding: 1.2rem;
  color: var(--font-color-2);
  background-color: var(--accent-1);
  border: 1px solid var(--background-3);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-3);
}

.accordion__title:hover {
  background-color: var(--background-3);
  color: var(--font-color-1);
  transition: all 0.8s;
}

.accordion {
  position: relative;
  /* max-width: 550rem; */
  margin: 9.5rem 1rem;   /* accordion width */
  border: 1px solid var(--background-3);
  box-shadow: var(--box-shadow-1);
  font-size: var(--font-size-4);
  row-gap: 10px;
}

.accordion__content {
  padding: 1.2rem;
  color: var(--font-color-2);
}

/* MEDIA  -------------------------------------------- */

/* @media screen and (min-width: 700px) {
  .main__body-about {
  padding: 0px 15%;
}
  .main__about-header {  
    margin: 7rem 3rem;
    font-size: 3rem;
  }

  .accordion__title {
    font-size: 1.4rem;
  }

  .accordion {
    margin: 12rem 3rem;
    font-size: 1.4rem;
  }
} */
